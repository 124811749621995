import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ContentLoader from 'react-content-loader';
import '@fontsource/plus-jakarta-sans';
import '@fontsource/montserrat';
import '../styles/Homescreen.css';
import Navbar from '../components/navbar/Navbar';
import InputContainer from '../components/input/InputContainer';
import doggoGif from '../components/doggo.gif';
import LogRocket from 'logrocket';
LogRocket.init('prashaants-portfolio/civi-tqwjr');

const backgroundImages = [
  '/assets/images/backgrounds/background-image-1.png',
  '/assets/images/backgrounds/background-image-2.png',
  '/assets/images/backgrounds/background-image-3.png',
  '/assets/images/backgrounds/background-image-4.png',
  '/assets/images/backgrounds/background-image-5.png',
  '/assets/images/backgrounds/background-image-6.png',
  '/assets/images/backgrounds/background-image-7.png',
];



// Google Tag Manager setup
(function() {
  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-ZZ1GS2B1J2';
  document.head.appendChild(script);

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }

  gtag('js', new Date());
  gtag('config', 'G-ZZ1GS2B1J2');
})();


const images = [
  '/assets/images/stock/img1.png',
  '/assets/images/stock/img2.png',
  '/assets/images/stock/img3.png',
  '/assets/images/stock/img4.png',
  '/assets/images/stock/img5.png',
  '/assets/images/stock/img6.png',
  '/assets/images/stock/img7.png',
  '/assets/images/stock/img8.png',
  '/assets/images/stock/img9.png',
  '/assets/images/stock/img10.png',
  '/assets/images/stock/img11.png',
  '/assets/images/stock/img12.png',
  '/assets/images/stock/img13.png',
  '/assets/images/stock/img14.png',
  '/assets/images/stock/img15.png',
];

const MyLoaderCard = (props) => (
  <div className="loader-card">
    <ContentLoader
      speed={2}
      width={window.innerWidth * 0.8}
      height={200}
      viewBox={`0 0 ${window.innerWidth * 0.8} 160`}
      backgroundColor="#BFBFBF"
      foregroundColor="#ecebeb"
      {...props}
    >
      <circle cx="40" cy="40" r="30" />
      <rect x="90" y="20" rx="4" ry="4" width="320" height="8" />
      <rect x="90" y="40" rx="4" ry="4" width="280" height="8" />
      <rect x="90" y="60" rx="4" ry="4" width="220" height="8" />
      <rect x="20" y="100" rx="4" ry="4" width="100" height="20" />
      <rect x="140" y="100" rx="4" ry="4" width="100" height="20" />
      <rect x="260" y="100" rx="4" ry="4" width="100" height="20" />
      <rect x="20" y="140" rx="4" ry="4" width="400" height="8" />
      <rect x="20" y="160" rx="4" ry="4" width="380" height="8" />
      <rect x="20" y="180" rx="4" ry="4" width="360" height="8" />
    </ContentLoader>
  </div>
);

const ThickLinesLoader = (props) => (
  <div className="thick-lines-loader">
    <ContentLoader
      speed={2}
      width={window.innerWidth * 0.8}
      height={60}
      viewBox={`0 0 ${window.innerWidth * 0.8} 60`}
      backgroundColor="#BFBFBF"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="0" y="10" rx="4" ry="4" width={window.innerWidth * 0.7} height="20" />
      <rect x="0" y="35" rx="4" ry="4" width={window.innerWidth * 0.6} height="20" />
      <rect x="0" y="60" rx="4" ry="4" width={window.innerWidth * 0.8} height="20" />
    </ContentLoader>
  </div>
);

const MyLoader = () => (
  <div className="loader-container">
    <ThickLinesLoader />
    <MyLoaderCard />
  </div>
);

export default function HomeScreen() {
  const [url, setUrl] = useState('');
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPrompts, setShowPrompts] = useState(true);
  const [message, setMessage] = useState('Paste a News or TikTok link, or type a cause you are passionate about 💛'); // Initial dummy message
  const textareaRef = useRef(null);
  const [backgroundImage, setBackgroundImage] = useState(''); // Initial background image state




  const suggestedPrompts = [
    "https://www.tiktok.com/@human.1011/video/7261247686307745067?is_from_webapp=1&sender_device=pc",
    "https://www.tiktok.com/@basicallybetsy2.0/video/7368150989473336618?q=congo&t=1724298301172",
    "Non-profits supporting Palestinian self-determination while advocating for Israeli security and coexistence.",
    "Charities focused on demilitarization and protecting indigenous rights in Hawaii.",
    "Organizations that protect LGBTQ+ youth from homelessness and provide safe, inclusive spaces in Los Angeles and San Francisco.",
  ];

  useEffect(() => {
    // Randomly select a background image on component mount
    const randomImage = backgroundImages[Math.floor(Math.random() * backgroundImages.length)];
    setBackgroundImage(randomImage);


    const handleScroll = () => {
      const backgroundImage = document.querySelector('.background-image');
      const resultWrapper = document.querySelector('.result-wrapper');

      // Calculate the scroll position relative to the result wrapper
      const scrollPosition = resultWrapper.scrollTop;

      // Shrink the background image size based on scroll position
      let newScale = Math.max(0.8, 1 - scrollPosition * 0.001); // Adjust scale factor as needed
      backgroundImage.style.transform = `scale(${newScale})`;
    };

    // Attach scroll event listener
    const resultWrapper = document.querySelector('.result-wrapper');
    resultWrapper.addEventListener('scroll', handleScroll);

    return () => {
      // Clean up event listener on component unmount
      resultWrapper.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [url]);

  const handlePromptClick = (prompt) => {
    handleSubmit(prompt);  // Pass the prompt directly to handleSubmit
  };


  const loadingMessages = [
    "Scanning your input… 🔍",
    "Searching for the best charities… 💡",
    "Reviewing top causes that matter… 🌍",
    "Matching you with organizations that align… 🤝",
    "Almost there—finding your perfect match… ⏳",
    "Connecting you with impactful options… 🔗",
    "Finalizing the list… hang tight… 📝",
    "Ready to dive in? Your results are coming… 🚀",
  ];

  const handleSubmit = async (inputUrl = '') => {
    const finalUrl = typeof inputUrl === 'string' ? inputUrl.trim() : url.trim();
  
    if (!finalUrl) {
      alert('Please enter a valid URL or select a prompt.');
      return;
    }
  
    setLoading(true);
    setShowPrompts(false);
  
    let messageIndex = 0;
    const intervalId = setInterval(() => {
      setMessage(loadingMessages[messageIndex]);
      messageIndex = (messageIndex + 1) % loadingMessages.length;
    }, 2000);
  
    // const baseURL = 'http://localhost:8080';
    const baseURL = 'https://api.civi-action.org'; 


    
    // Record the start time
    const startTime = Date.now();
  
    try {
      const isVideoLink = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be|vimeo\.com|tiktok\.com|instagram\.com\/(reel|p))/.test(finalUrl);
  
      if (isVideoLink) {
        try {
          const thumbnailResponse = await axios.post(`${baseURL}/download-thumbnail-video`, { url: finalUrl });
          const thumbnailUrl = thumbnailResponse.data.mediaUrl;
          document.querySelector('.background-image').src = thumbnailUrl;
        } catch (thumbnailError) {
          console.error('An error occurred while fetching the thumbnail.', thumbnailError);
        }
      }
  
      const response = await axios.post(`${baseURL}/process-url`, { url: finalUrl });
      const parsedResponse = JSON.parse(response.data.response);
      setResult(parsedResponse);
  
      // if (parsedResponse.charities) {
      //   await verifyCharities(parsedResponse.charities);  // Verify all charities in the list
      // } 
  
      setMessage(parsedResponse.perMsg);
  
      // Record the end time and calculate total time taken
      const endTime = Date.now();
      const totalTime = endTime - startTime;
      console.log(`Total time taken: ${totalTime} ms`);
  
    } catch (error) {
      console.error('An error occurred while processing the URL.', error);
      alert('Failed to process the URL. Please try again.');
      setResult(null);
      setMessage("Something went wrong. Please try again.");
    } finally {
      clearInterval(intervalId);
      setLoading(false);
    }
  };
  

  return (
    <div className="container">
      <Navbar />
      <div className="background-container">
        <img className="background-image" src={backgroundImage} alt="" />
      </div>

      <div className="result-wrapper">
      <p className="result-message">{message}</p>
        {/* Display the GIF loader during loading */}
        {loading && (
          <div className="gif-loader">
            <img src={doggoGif} alt=""/>
          </div>
        )}
        
        {showPrompts && (
          <div className="suggested-prompts">
            {suggestedPrompts.map((prompt, index) => (
              <button
                key={index}
                className="prompt-button"
                onClick={() => handlePromptClick(prompt)}
              >
                {prompt}
              </button>
            ))}
          </div>
        )}

         
  

        {loading ? (
          <MyLoader />
        ) : (
          result && (
            <div className="result-container">
              {Array.isArray(result.charities) && result.charities.length > 0 ? (
                result.charities.map((charity, index) => {
                  const randomImage = images[Math.floor(Math.random() * images.length)];



                  return (
                    <div
                      key={index}
                      className="charity-container"
                    >
                      <div className="charity-row">
                        <img className="charity-image" src={randomImage} alt=""/>
                        <div className="charity-text-container">
                          <p className="charity-name">{charity.n}</p>
                          <p className="charity-location">{charity.loc}</p>
                        </div>
                      </div>
                      <div className="labels-container">
                        {charity.g === "True" && (
                          <span className="label grassroots">Grassroots</span>
                        )}
                        {charity.vd === "True" && (
                          <span className="label volunteer-driven">Volunteer-Driven</span>
                        )}
                        {charity.ad === "True" && (
                          <span className="label advocacy">Advocacy</span>
                        )}
                        {charity.ed === "True" && (
                          <span className="label educational">Educational</span>
                        )}
                        {charity.env === "True" && (
                          <span className="label environmental">Environmental</span>
                        )}
                        {charity.h === "True" && (
                          <span className="label health">Health</span>
                        )}
                        {charity.rel === "True" && (
                          <span className="label relief">Relief</span>
                        )}
                        {charity.c === "True" && (
                          <span className="label cultural">Cultural</span>
                        )}
                      </div>
                      <p className="charity-reason">{charity.rea}</p>
                      <a href={charity.link} target="_blank" rel="noopener noreferrer"><button className="donate-button"> Donate</button></a>
                      


                    </div>


                  );
                })
              ) : (
                <p>No charities found.</p>
              )}

            </div>
          )
        )}
        <InputContainer url={url} setUrl={setUrl} handleSubmit={handleSubmit} />
      </div>
    </div>
  );
}
